
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'square'
})

export default class extends Vue {
  currentIndex = 0
  currentIndex1 = 0
  switchTab = 0
  isMode = false
  titleList = [{
    name: '智护通平台',
    introduce: '核心业务场景',
    img: require('@/assets/img/square/core.png'),
    id: '#core'
  },
  {
    name: '智能硬件设备',
    introduce: ' 养护生态监测',
    img: require('@/assets/img/square/environment.png'),
    id: '#device'
  },
  {
    name: '智能硬件设备',
    introduce: ' 安全风险监测',
    img: require('@/assets/img/square/safe.png'),
    id: '#device'
  },
  {
    name: '智能硬件设备',
    introduce: ' 无人作业设备',
    img: require('@/assets/img/square/unmanned.png'),
    id: '#device'
  },
  {
    name: '增值业务',
    introduce: '',
    img: require('@/assets/img/square/valueadded.png'),
    id: '#service'
  }]

  private sceneList = [
    {
      name: '智慧喷灌系统',
      tag: '草坪喷灌、花箱滴灌',
      content: '实时监测土壤环境温度、湿度、降雨量、等数据，通过智慧喷灌模型精准分析预测，向终端自动下达正确的喷灌决策，智能补充植物所需水份',
      img: require('@/assets/icon/square/zhihuipengguan.svg')
    },
    {
      name: '项目资料汇编',
      tag: '权限管控、标签管理、文档推荐',
      content: '建议项目资料归档标准化管理，规范要求进行资料编写上传，实现线上管理，支持快速查询，实时查看',
      img: require('@/assets/icon/square/ziliao.svg')
    },
    {
      name: '项目移交盘点',
      tag: '资源清单、事项跟踪、盘点工具',
      content: '项目进场移交工作线上化处理，支持清单导入，盘点事项流程化处置；利用智能硬件完成树径、面积快速测算',
      img: require('@/assets/icon/square/yijiao.svg')
    },
    {
      name: '人员智能监管',
      tag: '实时定位、实名制考勤',
      content: '基于项目养护工人管理需求，通过智能硬件实现实时定位，规范作业；基于人脸识别AI技术实现项目实名制考勤及上工人数智能计算',
      img: require('@/assets/icon/square/renyuan.svg')
    },
    {
      name: '病虫害防治',
      tag: '智能识别、治理推荐',
      content: '通过虫害监测设备集诱捕、拍照、识别、分析于一体，智能识别虫害类型及预警，集成虫害库、药剂库、专家库内容，精准指导虫害治理与防护策略',
      img: require('@/assets/icon/square/bingchong.svg')
    },
    {
      name: '古树名木保护',
      tag: '倾斜监测、作业指导',
      content: '针对古树名木实现一树一码管理，关联养护指导、养护日志，精准溯源。集成智能硬件设备，完成树木倾斜、根茎长势智能监测',
      img: require('@/assets/icon/square/gushu.svg')
    },
    {
      name: '机械设备调度',
      tag: '实时定位、用水用油监测',
      content: '对机械设备作业运行实时监控、对设备违规操作异常情况及时预警;基于智能化设备监测数据，进行设备功效使用分析',
      img: require('@/assets/icon/square/jixie.svg')
    },
    {
      name: '固定资产管理',
      tag: '出入库管理、调拨、报废',
      content: '通过对大型资产进行定位管理监控使用记录、资产状态信息同步以便完成项目间调拨使用；同时增加项目报废、出售业务线上流程化操作，实现资产的全生命周期管理，提高资产利用率与质量',
      img: require('@/assets/icon/square/guding.svg')
    },
    {
      name: '养护作业材料管理',
      tag: '全生命周期管理',
      content: '养护作业材料全生命周期管理，从使用计划制定、材料采购，材料出入库、库存盘点、现场使用等多维度进行材料各阶段分项管理。通过精细化管理手段，确保材料使用得当',
      img: require('@/assets/icon/square/yanghu.svg')
    }
  ]

  private deviceTitle = [{
    name: '养护生态监测',
    img: 'shengtai'
  },
  {
    name: '安全风险监测',
    img: 'anquan'
  },
  {
    name: '无人作业设备',
    img: 'device'
  }]

  private device = [[
    {
      name: '土壤监测',
      img: require('@/assets/img/square/turang.png'),
      content: '实时监测和记录土壤水分、土壤温度、土壤电导率、肥力等参数的设备，为养护决策提供科学数据支撑。'
    },
    {
      name: '水质监测',
      img: require('@/assets/img/square/shuizhi.png'),
      content: '用于监测和评估水质状况的设施，可配置水温、水PH、水电导率、溶解氧、浊度等多种参数监测探头，提供实时、精准的水质监测数据，预警潜在的水质问题。'
    },
    {
      name: '环境监测',
      img: require('@/assets/img/square/huanjing.png'),
      content: '提供局部范围内气象环境监测和预警服务，可配置空气温湿度、风速风向、降雨量、大气压等参数监测探头，数据实时上传，提高气象数据的应用价值和决策的准确性。'
    },
    {
      name: '虫情监测',
      img: require('@/assets/img/square/chongqing.png'),
      content: '符合《GB/T 24689.1-2009植物保护机械 虫情测报灯》要求，实现了害虫的诱集、分类统计、实时报传、远程监测、虫害预警等。'
    }
  ],
  [
    {
      name: '智慧监控',
      img: require('@/assets/img/square/jiankong.png'),
      content: '实现对监控区域内的实时监控、多种异常检测和智能预警等功能，还能够与其他安防系统实现联动，为用户提供更全面、智能的安全保障服务。'
    },
    {
      name: '智慧烟感',
      img: require('@/assets/img/square/yangan.png'),
      content: '智能化的烟雾探测设备，通过监测烟雾浓度来实现火灾预警和报警功能。具有更高的灵敏度和准确性，具备远程监控和智能化管理功能，提高火灾防控能力。'
    },
    {
      name: '积水监测',
      img: require('@/assets/img/square/jishui.png'),
      content: '及时、准确地监测地面积水情况，为客户预防和解决积水问题提供数据支持，保障交通安全和公共安全。'
    }
  ],
  [
    {
      name: '植保无人机',
      img: require('@/assets/img/square/zhibao.png'),
      content: '是用于农林植物保护作业的无人驾驶飞机。通过地面遥控或导航飞控来实现药剂、种子、粉剂等喷洒作业，提高喷洒作业安全性，降低资源成本，对推动植物病虫害统防统治和农药减量效果显著。'
    },
    {
      name: '清扫机器人',
      img: require('@/assets/img/square/qingsao.png'),
      content: '高效、智能的清洁设备，用于户外环境自动化清扫保洁作业。可规划清扫路径，具备避障功能，可在夜间或清晨进行清扫作业，显著提高公园的保洁水平和游客体验。'
    },
    {
      name: '割草机器人',
      img: require('@/assets/img/square/gecao.png'),
      content: 'AI+割草机器人，采用智能方式重新定义草坪的维护，为草坪提供细致的打理和守护，高频剪草、标准化作业。替代传统人工割草，无需人工操作、环保高效。'
    },
    {
      name: '水草收割无人船',
      img: require('@/assets/img/square/shuicao.png'),
      content: '用于清理水生植物和水草的设备。该系统通过高效的割草船和一系列附加设备，可以有效地割除、收集和运输水草，从而帮助维护水体的清洁和健康。'
    },
    {
      name: '泵房远程控制',
      img: require('@/assets/img/square/bengfang.png'),
      content: '泵房远程智能化控制，包括水泵的远程启动和停止、运行状态的实时监测、故障预警和自动报警等。可随时远程查看泵房的运行状况，实现监控和管理，提高泵房的运行效率和安全性。'
    },
    {
      name: '喷灌智能控制',
      img: require('@/assets/img/square/penguan.png'),
      content: '实现自动化控制的灌溉系统，支持远程控制，通过传感器、控制器等设备，对灌溉进行精准控制和智能化管理，显著提高灌溉效率。'
    }
  ]
  ]

  private serviceList = [
    {
      name: '远程诊断 专家指导',
      content: '联合多所院校教授及行业内资深养护专家，提供养护问题远程诊断及对应解决措施服务；并可结合实例进行补肥、杀虫、修剪等养护要求现场指导',
      img: require('@/assets/img/square/yuancheng.png')
    },
    {
      name: '草坪健康检测',
      content: '与国内知名草坪施工与养护公司合作，提供土壤、草坪健康测试服务；依据测试结果出具权威检测报告及对应下一阶段养护指导建议及改善措施',
      img: require('@/assets/img/square/caoping.png')
    }
  ]

  toDetail (index: number) {
    // 0智慧喷灌 3人员智能监管
    if (index === 0 || index === 3) {
      this.$router.push({
        name: 'squareDetail',
        params: {
          id: index.toString()
        }
      })
    }
  }

  chooseTab (index: number, selector: any) {
    this.currentIndex = index
    ;(this.$parent as any).$el.scrollTo(0, this.$el.querySelector(selector).offsetTop - 150)
    // 硬件设备需要自动选中下面的类目
    if ([1, 2, 3].includes(index)) {
      this.currentIndex1 = index - 1
      this.switchTab = 0
    }
  }

  chooseTab1 (index: number) {
    this.currentIndex1 = index
    this.switchTab = 0
  }

  next (index: number) {
    this.switchTab = this.switchTab + index
    ;(this.$refs.scroll as any).scrollLeft = window.innerWidth * 0.212 * this.switchTab
  }
}
